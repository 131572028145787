export default {
  public: [],
  admin: [
    // 帳務總覽
    {
      path: 'accounting-overview',
      name: 'accounting-overview',
      component: () => import('@/modules/accounting/views/accountOverview.vue'),
    },

    // 帳務列表
    {
      path: 'accounting',
      name: 'accounting',
      component: () => import('@/modules/accounting/views/accountingList.vue'),
    },

    // 帳務表單
    {
      path: 'accounting/create',
      name: 'accounting-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/accounting/views/accountingForm.vue'),
    },
    {
      path: 'accounting/update/:target',
      name: 'accounting-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/accounting/views/accountingForm.vue'),
    },
  ],
}
